<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="修改發送時間"
      :visible.sync="showModal"
      width="50%"
    >
      <div v-if="email">
        <el-date-picker
          style="width: 100%"
          v-model="email.send_at"
          type="datetime"
          placeholder="选择日期时间"
          default-time="12:00:00"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <div class="text-center mt-2">
          本郵件將在
          <b>{{ email.send_at }} ({{ showDay(email.send_at) }})</b>
          發送到對方信箱
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>

        <el-button
          v-if="email && email.id"
          type="primary"
          @click="updateScheduledMailCompose()"
          >修改</el-button
        >
      </span>
    </el-dialog>
    <el-table
      id="enrollment-table"
      :data="list"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      @row-click="viewEnrolledSessions"
    >
      <el-table-column width="40" :label="``">
        <i class="fas fa-file-code"></i>
      </el-table-column>
      <el-table-column width="150" :label="`Title`">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column :label="`Content`">
        <template slot-scope="scope">
          <div v-html="scope.row.content"></div>
        </template>
      </el-table-column>

      <el-table-column width="250" :label="`是否可以添加參數`">
        <template slot-scope="scope">
          <!-- {{ instant.formatDate(scope.row.created_at) }} -->
          <span v-if="scope.row.has_variables">
            可以添加
          </span>
          <span v-else>
            不可以添加
          </span>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope" v-if="!scope.row.sent_at">
          <el-tooltip content="Delete" placement="top">
            <el-button
              class="mr-2"
              plain
              type="danger"
              icon="el-icon-delete-solid"
              size="mini"
              @click.stop="deleteEmail(scope.row.id)"
            />
          </el-tooltip>
          <el-tooltip content="Edit" placement="top">
            <router-link
              :to="{
                name: 'EditEmail',
                params: {
                  id: scope.row.id
                }
              }"
            >
              <el-button
                plain
                type="success"
                icon="el-icon-edit-outline"
                size="mini"
              ></el-button>
            </router-link>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 text-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="changePage"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper, instant } from "@ivy-way/material";
import mailApi from "@/apis/mail";
import moment from "moment";
export default {
  metaInfo() {
    return {
      title: "Email List - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: {},

  data() {
    return {
      moment: moment,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      showModal: false,
      email: {
        id: null,
        send_at: null
      },
      list: [],
      currentPage: 1,
      pageSize: 0,
      total: 0
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  watch: {},

  async mounted() {
    this.getTemplateOptions();
  },

  methods: {
    showDay(date) {
      const weekArr =["週日","週一","週二","週三","週四","週五","週六"];
      const weekDay = this.moment(date).format("d");
      return weekArr[weekDay];
    },
    async updateScheduledMailCompose() {
      try {
        await mailApi.updateEmail(this.email.id, { ...this.email });
        await this.$message({
          message: this.$t("message.set_success"),
          type: "success"
        });
        this.getScheduledMails();
        this.showModal = false;
      } catch (e) {
        this.$message({
          showClose: true,
          message: e.response.data.message,
          type: "error"
        });
      }

      // this.$router.push({ name: "Home" });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.sent_at) {
        return "success-row";
      }
      return "";
    },
    async deleteEmail(id) {
      this.$confirm(
        this.$t("courses.delete_alert"),
        this.$t("courses.delete"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(() => {
          mailApi.deleteEmail(id);
          this.getScheduledMails();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.cancel")
          });
        });
    },
    changeDate(index) {
      this.email = null;
      this.email = JSON.parse(JSON.stringify(this.list[index]));
      this.showModal = true;
    },
    changePage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page
        },
        params: {
          ...this.$route.params
        }
      });
    },
    async getTemplateOptions() {
      const templateRes = await mailApi.getTemplateOptions();
      this.list = templateRes.mail_templates;
    },
    viewEnrolledSessions(row, column, event) {
      this.$emit("routeToEnrollmentSession", row.id);
    }
  }
};
</script>

<style scoped>
.changeDate {
  color: #42a16a;
  font-weight: 500;
  cursor: pointer;
}
::v-deep .el-table .success-row {
  /* background: #42a16a; */
  color: #42a16a;
  font-weight: 700;
}
</style>
